import {Controller} from "@hotwired/stimulus"
import TomSelect from 'tom-select/dist/esm/tom-select.complete.js'
import helpers from '../src/helpers'

export default class extends Controller {
  static values = {
    focus: Boolean,
    status: String,
    productType: String,
    articleType: String,
    consumerId: Number,
    time: String,
    timeFrom: String,
    timeTo: String,
    orgUnitIds: Array
  }

  static targets = ['input', 'select']

  connect() {
    if (this.inSortableGhost()) {
      return
    }

    let config = {
      plugins: {},
      render: {
        no_results: this.#noResultFound,
        loading: this.#loading
      },
      dropdownParent: this.inputTarget.getAttribute('data-dropdown-parent')
    }

    if (this.inputTarget.getAttribute('data-allow-empty')) {
      config.allowEmptyOption = true
    }
    if (this.inputTarget.getAttribute('data-preload')) {
      config.preload = true
    }

    config = {...config, ...this.getResourceConfig()}

    if (this.inputTarget.getAttribute('multiple')) {
      config.plugins['remove_button'] = { title: '' }
    }

    this.tomSelect = new TomSelect(this.inputTarget, config)

    if (this.focusValue) {
      this.tomSelect.focus()
    }

    this.tomSelect.on('item_add', (value, item) => {
      let selectedItem, type

      if (item.dataset.item) {
        selectedItem = JSON.parse(item.dataset.item)
        type = item.dataset.item_type
      } else {
        selectedItem = value
      }

      this.dispatch('add_item', {detail: {type: type, item: selectedItem}})
    })

    window.addEventListener("set-element-attribute", (event) => {
      if (this.element.id === event.detail.elementId) {
        this.tomSelect.addOption(event.detail.payload)
        this.tomSelect.addItem(event.detail.value)
      }
    })
  }

  disconnect() {
    if (!this.inSortableGhost()) {
      this.tomSelect.destroy()
    }
  }

  inSortableGhost() {
    return this.element.closest('.sortable-ghost') !== null
  }

  getResourceConfig() {
    if (this.element.id.match(/article_ingredient|article_id/g))
      return this.getArticlesConfig()
    else if (this.element.id.match(/product_id/))
      return this.getProductsConfig()
    else if (this.element.id.match(/product_configuration_id/))
      return this.getProductConfigurationsConfig()
    else if (this.element.id.match(/consumer_id/))
      return this.getConsumersConfig()
    else if (this.element.id.match(/article_group_id/g))
      return this.getArticleGroupsConfig()
    else if (this.element.id.match(/supplier_item_id/g))
      return this.getSupplierItemConfig()
    else
      return {}
  }

  getSupplierItemConfig() {
    const renderFct = this.renderFct('SupplierItem', (data, escape) => {
      return helpers.htmlToElement(`<div>${escape(data.name)}</div>`)
    })

    const urlBuilderFct = (query) => {
      return '/supplier_items.json?q[name_cont]=' + query
    }

    return {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      plugins: ['virtual_scroll'],
      firstUrl: urlBuilderFct,
      load: this.loadResourceFct(urlBuilderFct),
      render: {
        option: renderFct,
        item: renderFct,
        no_results: this.#noResultFound,
        no_more_results: this.#noMoreResults,
        loading_more: this.#loadingMore,
        loading: this.#loading
      }
    }
  }

  getProductsConfig() {
    const renderFct = this.renderFct('ProductConfiguration', (data, escape) => {
      const infoData = helpers.camelCaseKeys(data)
      const infoText = [
        (infoData.number || null),
        infoData.productType === 'assembly' ? `(${infoData.productTypeHuman})` : null
      ].filter(x => !!x).join(' ')

      return helpers.htmlToElement(`<div>${escape(infoData.name)}<span class="small text-muted ps-1">${escape(infoText)}${data.softDestroyedWarning || ''}</span></div>`)
    })

    const urlBuilderFct = (query) => {
      let url = '/products.json?q[name_or_number_cont]=' + query;
      if (this.orgUnitIdsValue) {
        this.orgUnitIdsValue.forEach(orgUnitId => url += `&q[org_unit_id_in][]=${orgUnitId}`)
      }
      if (this.productTypeValue) {
        this.productTypeValue.split(',').forEach(productType => url += `&q[product_type_in][]=${productType}`)
      }
      return url
    }

    return {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name', 'number'],
      plugins: ['virtual_scroll'],
      firstUrl: urlBuilderFct,
      load: this.loadResourceFct(urlBuilderFct).bind(this),
      render: {
        option: renderFct,
        item: renderFct,
        no_results: this.#noResultFound,
        no_more_results: this.#noMoreResults,
        loading_more: this.#loadingMore,
        loading: this.#loading
      }
    }
  }

  getArticleGroupsConfig() {
    const renderFct = this.renderFct('ArticleGroup', (data, escape) => {
      return helpers.htmlToElement(`<div>${escape(data.name)}</div>`)
    })

    const urlBuilderFct = (query) => {
      return '/article_groups.json?q[name_cont]=' + query
    }

    return {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      plugins: ['virtual_scroll'],
      firstUrl: urlBuilderFct,
      load: this.loadResourceFct(urlBuilderFct),
      render: {
        option: renderFct,
        item: renderFct,
        no_results: this.#noResultFound,
        no_more_results: this.#noMoreResults,
        loading_more: this.#loadingMore,
        loading: this.#loading
      }
    }
  }

  getArticlesConfig() {
    const renderFct = this.renderFct('Article', (data, escape) => {
      const infoData = helpers.camelCaseKeys(data)
      return helpers.htmlToElement(`
        <div>
            ${escape(infoData.name)}
            <span class="small text-muted ps-1">${escape(infoData.portionWeightHuman || '')}, ${escape(infoData.articleTypeHuman)}</span>
            ${data.softDestroyedWarning || ''}
          </div>`)
    })

    const urlBuilderFct = (query) => {
      let url =  '/articles.json?q[name_or_number_cont]=' + query
      if (this.articleTypeValue) {
        this.articleTypeValue.split(',').forEach(articleType => url += `&q[article_type_in][]=${articleType}`)
      }
      return url
    }

    return {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name', 'number'],
      plugins: ['virtual_scroll'],
      firstUrl: urlBuilderFct,
      load: this.loadResourceFct(urlBuilderFct),
      render: {
        option: renderFct,
        item: renderFct,
        no_results: this.#noResultFound,
        no_more_results: this.#noMoreResults,
        loading_more: this.#loadingMore,
        loading: this.#loading
      }
    }
  }

  getProductConfigurationsConfig() {
    const renderFct = this.renderFct('ProductConfiguration', (data, escape) => {
      const infoData = helpers.camelCaseKeys(data)
      const productNameInMenu =  infoData.productNameInMenuI18N === infoData.productName ? null : infoData.productNameInMenuI18N
      const name = infoData.name === infoData.productName ? null : infoData.name
      return helpers.htmlToElement(`
        <div>
            <div>
              ${escape(infoData.productName)}
              ${name ? `<span class="small text-muted ps-1">${escape(name || '')}</span>` : ''}
              ${productNameInMenu ? `<div class="small text-muted">${escape(infoData.productNameInMenuI18N || '')}</div>` : ''}
            </div>
        </div>`)
    })

    const urlBuilderFct = (query) => {
      let url = '/product_configurations.json?query=' + query
      const productTypes = (this.productTypeValue || 'placeholder,article,assembly').split(',')
      productTypes.forEach(productType => url += `&product_type_in][]=${productType}`)

      if (this.orgUnitIdsValue) {
        this.orgUnitIdsValue.forEach(orgUnitId => url += `&org_unit_id[]=${orgUnitId}`)
      }
      if (this.consumerIdValue && this.timeValue) {
        url += `&consumer_id=${this.consumerIdValue}&time=${this.timeValue}`
      }
      return url
    }

    return {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      plugins: ['virtual_scroll'],
      firstUrl: urlBuilderFct,
      load: this.loadResourceFct(urlBuilderFct),
      render: {
        option: renderFct,
        item: renderFct,
        no_results: this.#noResultFound,
        no_more_results: this.#noMoreResults,
        loading_more: this.#loadingMore,
        loading: this.#loading
      }
    }
  }

  getConsumersConfig() {
    const renderFct = this.renderFct('Consumer', (data, escape) => {
      const info = []
      const infoData = helpers.camelCaseKeys(data)
      if(infoData.roomNumber) {
        info.push(`Zimmer ${infoData.roomNumber}`)
      }

      if(infoData.bedNumber) {
        info.push(`Bett ${infoData.bedNumber}`)
      }

      return helpers.htmlToElement(`
        <div>
            <div>
              ${escape(infoData.fullName)}
              <div class="small text-muted">${info.length > 0 ? escape(info.join(', ')) : ''}</div>
            </div>
        </div>`)
    })

    const urlBuilderFct = (query) => {
      let url = '/consumers.json?q[first_name_or_last_name_or_username_cont]=' + query
      if (this.timeFromValue || this.timeToValue) {
        url += `&q[member_in_from]=${this.timeFromValue}&q[member_in_to]=${this.timeToValue}`
      }
      if (this.orgUnitIdsValue) {
        url += `&q[org_unit_ids][]=${this.orgUnitIdsValue}`
      }
      if (this.statusValue) {
        url += `&q[with_status]=${this.statusValue}`
      }
      return url
    }

    return {
      valueField: 'id',
      labelField: 'full_name',
      searchField: ['full_name'],
      plugins: ['virtual_scroll'],
      firstUrl: urlBuilderFct,
      load: this.loadResourceFct(urlBuilderFct),
      render: {
        option: renderFct,
        item: renderFct,
        no_results: this.#noResultFound,
        no_more_results: this.#noMoreResults,
        loading_more: this.#loadingMore,
        loading: this.#loading
      }
    }
  }

  renderFct(item_type, elementBuilderFct) {
    return (data, escape) => {
      const element = elementBuilderFct(data, escape)
      element.dataset.item = JSON.stringify(data)
      element.dataset.item_type = item_type
      return element
    }
  }

  loadResourceFct(urlBuilderFct) {
    return (query, callback) => {
      let url

      if (this.tomSelect.getUrl !== undefined) {
        url = this.tomSelect.getUrl(query)
      } else {
        url = urlBuilderFct(encodeURIComponent(query))
      }

      fetch(url)
        .then(response => response.json())
        .then(json => {
          if(json.pagination?.next){
            const nextUrl = urlBuilderFct(encodeURIComponent(query)) + `&page=${json.pagination.next}`
            this.tomSelect.setNextUrl(query, nextUrl);
          }

          // prevent scrolling to top (see https://github.com/orchidjs/tom-select/issues/556)
          const _scrollToOption = this.tomSelect.scrollToOption
          this.tomSelect.scrollToOption = () => {}
          callback(json)
          this.scrollToOption = _scrollToOption

        }).catch(() => {
        callback()
      })
    }
  }

  #loadingMore() {
    return '<div class="text-muted"><span class="spinner-border spinner-border-sm"></span>&nbsp;Weitere Ergebnisse werden geladen...</div>'
  }

  #noMoreResults() {
    return '<div class="text-muted">Keine weiteren Ergebnisse</div>'
  }

  #noResultFound() {
    return '<div class="ms-2 text-muted">Keine Ergebnisse gefunden</div>'
  }

  #loading() {
    return '<div class="text-muted"><span class="ms-2 spinner-border spinner-border-sm"></span></div>'
  }
}
